h1 {
  font-size: var(--fsz-xx-large);
  font-family: var(--ffy-semi-bold), sans-serif;
}

h2 {
  font-size: var(--fsz-x-large);
  font-family: var(--ffy-semi-bold), sans-serif;
}

h3 {
  font-size: var(--fsz-large);
  font-family: var(--ffy-semi-bold), sans-serif;
}

h4 {
  font-size: var(--fsz-x-medium);
  font-family: var(--ffy-semi-bold), sans-serif;
}

h5 {
  font-size: var(--fsz-medium);
  font-family: var(--ffy-semi-bold), sans-serif;
}

h6 {
  font-size: var(--fsz-small);
  font-family: var(--ffy-semi-bold), sans-serif;
}

p {
  font-size: var(--fsz-medium);
  font-family: var(--ffy-regular), sans-serif;
}

button:active {
  opacity: 0.7;
}

main {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
.app-root {
  background-color: var(--clr-ash-500);
  min-height: 100vh;
}
.page-title,
.page-sub-title {
  text-transform: capitalize;
}

.page-sub-title {
  margin: 5px 0 10px;
  color: var(--clr-grey-300);
  font-family: var(--ffy-regular);
}

.primary-color-button {
  padding: 8px 18px !important ;
}

.form-marker {
  color: var(--clr-red-300);
}

.link-text {
  color: var(--clr-blue);
  cursor: pointer;
}

.secondary-color-button {
  padding: 10px 40px;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: var(--fsz-xx-small);
  font-family: var(--ffy-medium);
  color: var(--clr-white);
  background-color: var(--clr-blue-200);
  cursor: pointer;
}
.secondary-color-button-outline {
  color: var(--clr-blue-200);
  background-color: var(--clr-white);
  border: 2px solid var(--clr-blue-200);
  transition: all 250ms;
}
.secondary-color-button-outline:hover {
  color: var(--clr-white);
  background-color: var(--clr-blue-200);
}
.secondary-color-button-outline:active {
  color: var(--clr-white);
  background-color: var(--clr-blue-200);
  opacity: 0.3;
}
.secondary-color-button--completed {
  background-color: var(--clr-green);
  border: 2px solid var(--clr-green);
  transition: all 250ms;
}
.secondary-color-button--completed:hover {
  border: 2px solid var(--clr-green);
  background-color: var(--clr-white);
  color: var(--clr-green);
}
.secondary-color-button:disabled {
  background-color: var(--clr-ash-100);
}
.secondary-color-button:disabled:active {
  background-color: var(--clr-ash-100);
}
