:root {
  --clr-red: #bb1414;
  --clr-red-100: #ca2e2e;
  --clr-red-200: #d32f2f;
  --clr-red-300: #ee4747;
  --clr-red-300: #ee5e5e;
  --clr-light-red: #ffe4de;

  --clr-brown: #ae6b1d;
  --clr-yellow: #ffedd8;
  --clr-blue: #1a73e8;
  --clr-blue-200: #079adb;
  --clr-blue-300: #e9f4ff;

  --clr-sky-blue: #d4f2ff;
  --clr-dark-blue: #1c2536;
  --clr-dark-blue-200: #06233c;
  --clr-dark-blue-100: #232f45;
  --clr-blue-green: #1c7196;
  --clr-green: #1f8e10;
  --clr-light-green: #def3d5;
  --clr-grey-200: #3e5060;
  --clr-grey-300: #6c737f;
  --clr-ash-100: #c4cdd5;
  --clr-ash-200: #dee5ea;
  --clr-ash-300: #e0e0e0;
  --clr-ash-400: #f4f6f8;
  --clr-ash-500: #f2f6f9;
  --clr-ash-600: #efefef;
  --clr-ash-700: #f6f6f6;

  --clr-white: #ffffff;
  --clr-black: #131313;
  --clr-black-100: #1a2027;

  --ffy-regular: "sfp-regular";
  --ffy-medium: "sfp-medium";
  --ffy-semi-bold: "sfp-semi-bold";
  --ffy-bold: "sfp-bold";
  --ffy-heavy: "sfp-heavy";
  --ffy-mrp-r: "mrp-r";
  --ffy-mrp-m: "mrp-m";
  --ffy-mrp-sb: "mrp-sb";
  --ffy-mrp-b: "mrp-b";
  --ffy-mrp-eb: "mrp-eb";

  --fsz-xxx-large: 38px;
  --fsz-xx-large: 32px;
  --fsz-x-large: 28px;
  --fsz-large: 24px;
  --fsz-xx-medium: 22px;
  --fsz-x-medium: 20px;
  --fsz-medium: 16px;
  --fsz-small: 15px;
  --fsz-x-small: 14px;
  --fsz-xx-small: 13.7px;
  --fsz-xxx-small: 12px;
}

@font-face {
  font-family: "sfp-regular";
  src: url("./assets/fonts/SF-Pro-Display-Regular.otf");
}

@font-face {
  font-family: "sfp-medium";
  src: url("./assets/fonts/SF-Pro-Display-Medium.otf");
}

@font-face {
  font-family: "sfp-semi-bold";
  src: url("./assets/fonts/SF-Pro-Display-Semibold.otf");
}

@font-face {
  font-family: "sfp-bold";
  src: url("./assets/fonts/SF-Pro-Display-Bold.otf");
}

@font-face {
  font-family: "sfp-heavy";
  src: url("./assets/fonts/SF-Pro-Display-Heavy.otf");
}
@font-face {
  font-family: "mrp-r";
  src: url("./assets/fonts/Manrope-Regular.ttf");
}
@font-face {
  font-family: "mrp-m";
  src: url("./assets/fonts/Manrope-Medium.ttf");
}
@font-face {
  font-family: "mrp-sb";
  src: url("./assets/fonts/Manrope-SemiBold.ttf");
}
@font-face {
  font-family: "mrp-b";
  src: url("./assets/fonts/Manrope-Bold.ttf");
}
@font-face {
  font-family: "mrp-eb";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf");
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-family: var(--ffy-regular);
  font-size: var(--fsz-small);
  color: var(--clr-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}
img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

input,
textarea,
select {
  font: inherit;
}

a,
a:focus,
a:visited {
  /* color: inherit; */
  text-decoration: none;
}

article,
aside,
details,
footer,
header,
nav,
section {
  display: block;
}

ul {
  list-style: none;
}

button {
  font: inherit;
  border: none;
  background-color: inherit;
}
